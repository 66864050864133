:root {
    --main-bg-color: #dcf8ff;
    --table-bg-color: #9dbbc2;
    --text-level-0: #657772;
    --text-level-1: #F9F6F2;
    --text-label: #eeeae7;
    --text-content: #f9f6f2;
    --game-over-bg-color: rgba(255, 255, 255, 0.7);
    --text-game-over: #1c2b49;
    --button-bg: #21a8e3;
    --button-bg-hover: #2cb4ef;
    --tile-bg: rgba(218, 233, 238, 0.35);

    --tile-bg-level-0: #daedee;
    --tile-bg-level-1: #c9eaee;
    --tile-bg-level-2: #7ad7f3;
    --tile-bg-level-3: #43d3ff;
    --tile-bg-level-4: #0cc7ff;
    --tile-bg-level-5: #00b3ff;
    --tile-bg-level-6: #edd073;
    --tile-bg-level-7: #edcc62;
    --tile-bg-level-8: #f38418;
    --tile-bg-level-9: #e74c2e;
    --tile-bg-level-10: #1c2b49;
    --tile-bg-level-11: #1c2b49;
}

@media (prefers-color-scheme: dark) {
    :root {
        --main-bg-color: #03252d;
        --table-bg-color: #293d42;
        --text-level-1: #E8E6E4;
        --text-label: #d3d2d1;
        --text-content: #e8e6e4;
        --tile-bg: rgba(218, 233, 238, 0.15);
        --game-over-bg-color: rgba(0, 0, 0, 0.6);
        --text-game-over: #c5c5c5;
        --button-bg: #197da9;
        --button-bg-hover: #2493c4;

        --tile-bg-level-0: #bac7c7;
        --tile-bg-level-1: #9fbbbe;
        --tile-bg-level-2: #67b8d0;
        --tile-bg-level-3: #3095b4;
        --tile-bg-level-4: #0aa9d9;
        --tile-bg-level-5: #0196d7;
        --tile-bg-level-6: #c4ab5d;
        --tile-bg-level-7: #9a843e;
        --tile-bg-level-8: #a85a0f;
        --tile-bg-level-9: #a23520;
    }
}

.container {
    background: var(--main-bg-color);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.table {
    background: var(--table-bg-color);
    width: 499px;
    height: 499px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    border-radius: 16px;
}

.next-tiles {
    background: var(--table-bg-color);
    display: flex;
    position: relative;
    margin-bottom: 30px;
    align-items: flex-end;
    width: 201px;
    height: 136px;
    border-radius: 16px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.scores {
    display: flex;
}

.game-title {
    font-size: 36px;
    font-weight: bold;
    color: var(--text-level-0);
    text-align: center;
    margin: 4px;
}

.game-sub-title {
    font-size: 18px;
    color: var(--text-level-0);
    text-align: center;
    margin-top: 4px;
    margin-bottom: 12px;
}

.ad {
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    color: var(--text-level-0);
}

.score {
    width: 110px;
    height: 55px;
    background: var(--table-bg-color);
    text-align: center;
    margin-left: 5px;
    border-radius: 16px;
}

.score .title {
    padding-top: 5px;
    font-weight: bold;
    color: var(--text-label);
}

.score .num {
    font-weight: bold;
    font-size: 24px;
    color: var(--text-content);
}

.game-over {
    width: 499px;
    height: 499px;
    background: var(--game-over-bg-color);
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
}

.game-over .title {
    margin-top: 120px;
    font-weight: bold;
    font-size: 48px;
    color: var(--text-game-over);
}

.game-over .replay {
    background: var(--button-bg);
    width: 200px;
    padding: 15px;
    color: var(--text-label);
    font-weight: bold;
    font-size: 22px;
    margin-top: 35px;
    border-radius: 12px;
    cursor: pointer;
}

.game-over .share {
    background: white;
    width: 200px;
    padding: 15px;
    color: var(--button-bg);
    font-weight: bold;
    font-size: 22px;
    margin-top: 12px;
    border-radius: 12px;
    cursor: pointer;
}

.game-over .replay:hover {
    background: var(--button-bg-hover);
}

.tile {
    position: absolute;
    width: 106px;
    height: 106px;
    border-radius: 16px;
}

.tile.bg {
    background: var(--tile-bg);
}

.tile.value {
    font-size: 55px;
    font-weight: bold;
    font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-level-1);
    pointer-events: none;
    transition: transform 0.2s, top 0.2s, left 0.2s, opacity 0.2s, outline 0.2s;
}

.tile.preview {
    opacity: 0.5;
}

.tile.next {
    transform-origin: left bottom;
}

.tile.value.level-0 {
    background: var(--tile-bg-level-0);
    color: var(--text-level-0);
}

.tile.value.level-1 {
    background: var(--tile-bg-level-1);
    color: var(--text-level-0);
}

.tile.value.level-2 {
    background: var(--tile-bg-level-2);
}

.tile.value.level-3 {
    background: var(--tile-bg-level-3);
}

.tile.value.level-4 {
    background: var(--tile-bg-level-4);
}

.tile.value.level-5 {
    background: var(--tile-bg-level-5);
}

.tile.value.level-6 {
    background: var(--tile-bg-level-6);
}

.tile.value.level-7 {
    background: var(--tile-bg-level-7);
    font-size: 44px;
}

.tile.value.level-8 {
    background: var(--tile-bg-level-8);
    font-size: 44px;
}

.tile.value.level-9 {
    background: var(--tile-bg-level-9);
    font-size: 36px;
}

.tile.value.level-10 {
    background: var(--tile-bg-level-10);
    font-size: 36px;
}

.tile.value.level-11 {
    background: var(--tile-bg-level-11);
    font-size: 32px;
}