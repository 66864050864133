@keyframes double-tap
{
    0%   {transform: scale(1); opacity: 0.5;}
    12.5%  {transform: scale(1.2); opacity: 0.4;}
    25%  {transform: scale(1); opacity: 0.5;}
    37.5%  {transform: scale(1.2); opacity: 0.4;}
    50%  {transform: scale(1); opacity: 0.5;}
}

.guide {
    pointer-events: none;
}

.guide .fullscreen {
    pointer-events: auto;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.guide .tooltip {
    background: rgba(255, 255, 255, 0.8);
    width: 400px;
    height: 55px;
    line-height: 55px;
    padding: 15px;
    position: absolute;
    bottom: 25px;
    left: 35px;
    text-align: center;
    font-size: 22px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 5px 20px 0;
}

@keyframes next-tip {
    0% {opacity: 0;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}

.guide .next-tip {
    width: 96px;
    height: 96px;
    border: dashed 5px #1c2b49;
    position: absolute;
    top: -150px;
    left: 80px;
    border-radius: 8px;
    animation: next-tip infinite 1s;
}

.tap-gesture {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: white;
    opacity: 0.5;
    position: absolute;
    animation: double-tap infinite 2s;
}